import React, { useContext, useEffect, useState } from "react";
import { navigate } from "@reach/router";
import HeaderContext from "../contexts/HeaderContext";
import Page from "../components/page/Page";
import "./404.css";

export default () => {
  const [redirectTimer, setRedirectTimer] = useState(5);
  const { headerHeight } = useContext(HeaderContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setRedirectTimer(prevTimer => prevTimer - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (redirectTimer < 0) {
      navigate("/");
    }
  }, [redirectTimer]);

  return (
    <Page footerHidden>
      <section
        className="e404"
        style={{ height: `calc(100vh - ${headerHeight}px)` }}
      >
        <div className="e404-redirect">
          <h2>Redirecting...</h2>
          <span className="e404-timer">{`0${Math.max(0, redirectTimer)}`}</span>
        </div>
        <div className="e404-main">
          <h1>404 Error</h1>
          <h2>Page not found...</h2>
          <span className="e404-description">
            Sorry, the page you are looking for doesn&apos;t exist or has been
            moved.
          </span>
        </div>
      </section>
    </Page>
  );
};
